window.$ = window.jQuery = require('jquery'); // required for bootstrap
window.Popper = require('popper.js'); // required for tooltip, popup...

import 'bootstrap';
import 'slick-carousel';

import './modules';

/*<![CDATA[*/
var defaultLanguage = /*[[${languageDefault}]]*/ 'fr';
/*]]>*/


$('document').ready(function(){
    createCarroussel();

    // STYLEGUIDE

    if ($('body').hasClass('styleguide')) {
        console.log('You are in the styleguide')
    }

    //TOOLTIPS
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });
});

function createCarroussel() {
    //Création du carrousel des produits vedettes
    $('#promo-products').slick({
        infinite: true,
        speed: 1000,
        dots: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2.4,
                    slidesToScroll: 2,
                    autoplay: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2.4,
                    slidesToScroll: 2,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            }
        ]
    });

    // var $status = $('.paging-info');
    // var $slickElement = $('#promo-products');
    //
    // $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    //     //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    //     var i = (currentSlide ? currentSlide : 0) + 1;
    //     $status.text((i) + '/' + (slick.slideCount));
    // });
}